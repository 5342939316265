import React from 'react';
import Values from '../components/Values/Values';

const AboutPage = () => (
  <>
    <div className="container-fluid th-hero-page">
      <div className="row">
        <div className="col-12">
          <div className="th-hero-content">
            <h1 className="th-hero-content__header">About us</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
    <div className="row mt-5">
        <div className="col-12 col-sm-6 values-title">
          <h4 className="mt-2 mb-2">Mission</h4>
          <p className="mb-5">To unleash the potential of our clients by helping them build world-class digital products, every step of the way.</p>
        </div>
        <div className="col-12 col-sm-6 values-title">
          <h4 className="mt-2 mb-2">Vision</h4>
          <p className="mb-5">TWe help customers reach markets they couldn't otherwise with unique competencies. We do it by building custom digital solutions, improving customer’s team expertise, or extending capacity.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 values-title">
          <h4 className="mt-2 mb-5">Our values:</h4>
        </div>
      </div>
      <Values />
    </div>
  </>
);

export default AboutPage;
